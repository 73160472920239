import React, { createContext, useContext } from "react";
import instance from "../utils/axiosInstance";
import { useAuthContext } from "./AuthContext";
import { base_url } from "../utils/baseUrl";

const MembershipTypeContext = createContext();

export const useMembershipTypeContext = () => useContext(MembershipTypeContext);

export const MembershipTypeProvider = ({ children }) => {
  const { user } = useAuthContext();

  const createMembershipType = async (membershipTypeData) => {
    try {
      const response = await instance.post(`${base_url}membershipType`, membershipTypeData, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  };

  const fetchMembershipTypes = async () => {
    try {
      const response = await instance.get(`${base_url}membershipType/membership`);
      return response.data.membershipTypes;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  };

  const deleteMembershipType = async (membershipTypeId) => {
    try {
      const response = await instance.delete(`${base_url}membershipType/delete/${membershipTypeId}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  };

  return (
    <MembershipTypeContext.Provider value={{ createMembershipType, fetchMembershipTypes, deleteMembershipType }}>
      {children}
    </MembershipTypeContext.Provider>
  );
};

import React, { createContext, useContext } from "react";
import instance from "../utils/axiosInstance";
import { base_url } from "../utils/baseUrl";

const TransactionContext = createContext();

export const useTransactionContext = () => useContext(TransactionContext);

export const TransactionProvider = ({ children }) => {
  const createTransaction = async (transactionData) => {
    try {
      const response = await instance.post(
        `${base_url}transaction`,
        transactionData
      );
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  };

  const fetchTransactions = async (filters = {}) => {
    try {
      const response = await instance.get(`${base_url}transaction/fetch`, {
        params: filters,
      });
      return response.data.transactions;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  };

  const deleteTransaction = async (transactionId) => {
    try {
      const response = await instance.delete(
        `${base_url}transaction/${transactionId}`
      );
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  };

  const editTransaction = async (transactionId, updatedTransactionData) => {
    try {
      const response = await instance.put(
        `${base_url}transaction/${transactionId}`,
        updatedTransactionData
      );
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  };

  const fetchServiceStats = async (serviceId) => {
    try {
      const response = await instance.get(
        `${base_url}transaction/stats/${serviceId}`
      );
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  };

  return (
    <TransactionContext.Provider
      value={{
        createTransaction,
        fetchTransactions,
        deleteTransaction,
        editTransaction,
        fetchServiceStats
      }}
    >
      {children}
    </TransactionContext.Provider>
  );
};

import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components
import { StyledChart } from "./components/chart";
import ScrollToTop from "./components/scroll-to-top/ScrollToTop";

import { AuthProvider } from "./context/AuthContext";
import { ServiceProvider } from "./context/ServiceContext";

import { MembershipProvider } from "./context/MembershipContext";
import { MembershipTypeProvider } from "./context/MembershipTypeContext";
import { CompanyProvider } from "./context/CompanyContext";
import { TransactionProvider } from "./context/TransactionContext ";
// ----------------------------------------------------------------------

export default function App() {
  return (
    <HelmetProvider>
      <AuthProvider>
        <ServiceProvider>
          <TransactionProvider>
            <CompanyProvider>
              <MembershipTypeProvider>
                <MembershipProvider>
                  <BrowserRouter>
                    <ThemeProvider>
                      <ScrollToTop />
                      <StyledChart />
                      <Router />
                    </ThemeProvider>
                  </BrowserRouter>
                </MembershipProvider>
              </MembershipTypeProvider>
            </CompanyProvider>
          </TransactionProvider>
        </ServiceProvider>
      </AuthProvider>
    </HelmetProvider>
  );
}

import React, { useRef } from "react";
import QRCode from "react-qr-code";
import { Button } from "@mui/material";

const BillComponent = ({ billData }) => {
  const billRef = useRef(null);

  const formattedCreatedAt = new Date(billData.createdAt).toLocaleString();
  const qrCodeValue = `${billData.transactionId}`;
  const handlePrint = () => {
    console.log("Printing initiated...");
  
    // Open a new window for printing
    const printWindow = window.open("", "_blank");
  
    if (!printWindow) {
      console.error("Failed to open a new window for printing.");
      return;
    }
  
    printWindow.document.open();
  
    console.log("Writing HTML content for printing...");
    printWindow.document.write(`
      <html>
        <head>
          <title>Print Bill</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              margin: 0;
              padding: 16px;
              width: 3in;
            }
            .bill-container {
              padding: 16px;
              width: 300px;
              margin: 0 auto;
              background-color: white;
              border-radius: 4px;
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            }
            .qr-code-container {
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: 16px;
            }
            .qr-code-image {
              width: 120px;
              height: 120px;
            }
            .gap-typography {
              margin-top: 8px;
            }
            .message-typography {
              margin-top: 16px;
              text-align: center;
              font-style: italic;
            }
            .print-button {
              display: none; 
            }
            @media print {
              .print-button {
                display: none;
              }
          </style>
        </head>
        <body>
          ${billRef.current.outerHTML}
        </body>
      </html>
    `);
    printWindow.document.close();

  console.log("Printing window content ready. Calling print()...");

  // Trigger the print dialog
  printWindow.print();

  console.log("Print dialog opened.");

  // Close the new window
  printWindow.close();

  console.log("Printing completed.");
};

  return (
    <div className="bill-container" ref={billRef}>
      <h2 className="title" align="center">
        Ticket Summary
      </h2>
      <p className="body-text" align="center">
        {billData.companyName}
      </p>
      <p className="body-text" align="center">
        Address: {billData.companyAddress}
      </p>
      <p className="body-text" align="center">
        {billData.serviceName}
      </p>
      <div className="qr-code-container" align="center">
        <QRCode className="qr-code-image" value={qrCodeValue} />
      </div>
      <p className="gap-typography" align="center">
        Total Price: NPR.{billData.selectedServicePrice}
      </p>
 
      <p className="gap-typography" align="center">
        Entry Time: {formattedCreatedAt}
      </p>
      <p className="message-typography">*** Thank you for your business! ***</p>
      <Button className="print-button" onClick={handlePrint} color="primary" variant="contained">
        Print
      </Button>
    </div>
  );
};

export default BillComponent;

import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from "@mui/material";
import BillComponent from "../components/bill/bill";
import { Cancel  } from '@mui/icons-material';

const BillModal = ({ isOpen, onClose, billData }) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
        <DialogActions>
      <IconButton onClick={onClose} color="primary">
          <Cancel />
          </IconButton>
      </DialogActions>
      <DialogTitle>Bill Details</DialogTitle>
    
      <DialogContent>
        <BillComponent billData={billData} /> 
      </DialogContent>

    </Dialog>
  );
};

export default BillModal;

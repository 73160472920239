import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  MenuItem,
  IconButton
} from "@mui/material";
import { useMembershipContext } from "../context/MembershipContext";
import { useMembershipTypeContext } from "../context/MembershipTypeContext";
import Iconify from "../components/iconify";

const MembershipPage = () => {
  const { fetchMembers, createMember, deleteMember  } = useMembershipContext();
  const { fetchMembershipTypes } = useMembershipTypeContext();
  const [members, setMembers] = useState([]);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [memberName, setMemberName] = useState("");
  const [memberAddress, setMemberAddress] = useState("");
  const [memberEmail, setMemberEmail] = useState("");
  const [membershipTypes, setMembershipTypes] = useState([]); // Fixed state declaration
  const [selectedMembershipType, setSelectedMembershipType] = useState("");
  const [memberPhone, setMemberPhone] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [typeStatus, setTypeStatus] = useState("inactive");
  const [activeMemberOnTop, setActiveMemberOnTop] = useState(true);
  const [selectedMembershipTypeDiscount, setSelectedMembershipTypeDiscount] = useState(0);

  const fetchMembersData = async () => {
    try {
      const fetchedMembers = await fetchMembers();
      setMembers(fetchedMembers.members);
    } catch (error) {
      console.error("Error fetching members:", error);
    }
  };

  const fetchMembershipTypesData = async () => {
    try {
      const fetchedMembershipTypes = await fetchMembershipTypes();
      setMembershipTypes(fetchedMembershipTypes);
    } catch (error) {
      console.error("Error fetching membership types:", error);
    }
  };

  useEffect(() => {
    fetchMembersData();
    fetchMembershipTypesData();
  }, []);

  const openDialog = () => {
    setDialogOpen(true);
    setTypeStatus("inactive");
  };

  const closeDialog = () => {
    setDialogOpen(false);
    setMemberName("");
    setMemberAddress("");
    setMemberEmail("");
    setMemberPhone("");
    setStartDate("");
    setEndDate("");
    setSelectedMembershipType("");
    setTypeStatus("inactive");
  };

  const handleAddMember = async () => {
    const newMember = {
      membershipTypeId: selectedMembershipType,
      memberName,
      memberAddress,
      memberEmail,
      memberPhone,
      startDate,
      endDate,
      status: typeStatus,
      discountPercentage: selectedMembershipTypeDiscount 
    };

    try {
      await createMember(newMember);
      fetchMembersData();
      closeDialog();
    } catch (error) {
      console.error("Error adding member:", error);
    }
  };
  
  const handleDeleteMember = async (memberId) => {
    try {
      await deleteMember(memberId);
      fetchMembersData();
    } catch (error) {
      console.error("Error deleting member:", error);
    }
  };

  const toggleFilter = () => {
    setActiveMemberOnTop((prev) => !prev);
  };

  useEffect(() => {
    const selectedType = membershipTypes.find((type) => type.id === selectedMembershipType);
    if (selectedType) {
      setSelectedMembershipTypeDiscount(selectedType.discountPercentage);
    } else {
      setSelectedMembershipTypeDiscount(0); // Set default value if no type is selected
    }
  }, [selectedMembershipType, membershipTypes]);

  return (
    <Container>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4" gutterBottom>
          Members
        </Typography>
        <Button
          variant="contained"
          startIcon={<Iconify icon="eva:plus-fill" />}
          onClick={openDialog}
        >
          Add
        </Button>
      </Stack>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>
              Status{" "}
              <IconButton
                  onClick={toggleFilter}
                  style={{ cursor: "pointer" }}
                >
                  {activeMemberOnTop ? (
                    <Iconify icon="ic:baseline-arrow-upward" />
                  ) : (
                    <Iconify icon="ic:baseline-arrow-downward" />
                  )}
                </IconButton>
              </TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {members
              .slice()
              .sort((a, b) => {
                if (activeMemberOnTop) {
                  return a.status === "active" ? -1 : 1;
                } else {
                  return a.status === "inactive" ? -1 : 1;
                }
              })
            .map((member) => (
              <TableRow key={member.id}>
                <TableCell>{member.id}</TableCell>
                <TableCell>{member.memberName}</TableCell>
                <TableCell>{member.memberAddress}</TableCell>
                <TableCell>{member.memberEmail}</TableCell>
                <TableCell>{member.memberPhone}</TableCell>
                <TableCell>{member.startDate}</TableCell>
                <TableCell>{member.endDate}</TableCell>
                <TableCell>
                  {
                    membershipTypes.find(
                      (type) => type.id === member.membershipTypeId
                    )?.membershipType
                  }
                </TableCell>
                <TableCell>{member.status}</TableCell>
                <TableCell>
              <Iconify
                icon="ic:outline-delete"
                onClick={() => handleDeleteMember(member.id)}
                style={{ cursor: "pointer" }}
              />
            </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={isDialogOpen} onClose={closeDialog}>
        <DialogTitle>Add New Member</DialogTitle>
        <Grid>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Name"
                  variant="outlined"
                  fullWidth
                  value={memberName}
                  onChange={(e) => setMemberName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Membership Type"
                  variant="outlined"
                  fullWidth
                  select
                  value={selectedMembershipType}
                  onChange={(e) => {
                    console.log("Selected Membership Type ID:", e.target.value);
                    setSelectedMembershipType(e.target.value);
                  }}
                >
                  {membershipTypes.map((type) => (
                    <MenuItem key={type.id} value={type.id}>
                      {type.membershipType}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Discount Percentage"
                  variant="outlined"
                  fullWidth
                  value={selectedMembershipTypeDiscount}
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  value={memberEmail}
                  onChange={(e) => setMemberEmail(e.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Address"
                  variant="outlined"
                  fullWidth
                  rows={4}
                  value={memberAddress}
                  onChange={(e) => setMemberAddress(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Phone"
                  variant="outlined"
                  fullWidth
                  value={memberPhone}
                  onChange={(e) => setMemberPhone(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="Start Date"
                  type="date"
                  variant="outlined"
                  fullWidth
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="End Date"
                  type="date"
                  variant="outlined"
                  fullWidth
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Status"
                  variant="outlined"
                  select
                  fullWidth
                  value={typeStatus}
                  onChange={(e) => setTypeStatus(e.target.value)}
                >
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="inactive">Inactive</MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleAddMember} color="primary">
              Add
            </Button>
          </DialogActions>
        </Grid>
      </Dialog>
    </Container>
  );
};

export default MembershipPage;
import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Button,
  IconButton,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import { useTransactionContext } from "../context/TransactionContext ";
import { useServiceContext } from "../context/ServiceContext";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import GetAppIcon from "@mui/icons-material/GetApp";
import ClearIcon from "@mui/icons-material/Clear";

const TransactionDetailsPage = () => {
  const { fetchTransactions } = useTransactionContext();
  const { fetchServices } = useServiceContext();
  const [transactions, setTransactions] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [selectedInterval, setSelectedInterval] = useState("daily");
  const [startDate, setStartDate] = useState(new Date()); // Start date state
  const [endDate, setEndDate] = useState(new Date()); // End date state
  const [exportMenuAnchor, setExportMenuAnchor] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedTransactions = await fetchTransactions();
        setTransactions(fetchedTransactions);

        const fetchedServices = await fetchServices();
        const serviceMapping = {};

        fetchedServices.forEach((service) => {
          serviceMapping[service.id] = {
            serviceId: service.id,
            serviceName: service.serviceName,
            numTransactions: 0,
            totalAmount: 0,
          };
        });

        fetchedTransactions.forEach((transaction) => {
          const serviceId = transaction.serviceId;
          if (serviceMapping[serviceId]) {
            serviceMapping[serviceId].numTransactions++;
            serviceMapping[serviceId].totalAmount += parseFloat(
              transaction.totalAmount
            );
          }
        });

        const serviceDataArray = Object.values(serviceMapping);
        setServiceData(serviceDataArray);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [fetchTransactions, fetchServices]);

  const filterTransactionsByInterval = (interval) => {
    const currentDate = new Date();
    let startDate;

    switch (interval) {
      case "daily":
        startDate = new Date();
        startDate.setHours(0, 0, 0, 0);
        break;
      case "weekly":
        startDate = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
        break;
      case "monthly":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 1,
          1
        );
        break;
      case "quarterly":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 3,
          1
        );
        break;
      case "yearly":
        startDate = new Date(currentDate.getFullYear() - 1, 0, 1);
        break;
      default:
        startDate = currentDate;
    }

    const filteredTransactions = transactions.filter((transaction) => {
      const transactionDate = new Date(transaction.created_at);
      return transactionDate >= startDate && transactionDate <= currentDate;
    });

    const serviceMapping = {};

    serviceData.forEach((service) => {
      serviceMapping[service.serviceId] = {
        serviceId: service.serviceId,
        serviceName: service.serviceName,
        numTransactions: 0,
        totalAmount: 0,
      };
    });

    filteredTransactions.forEach((transaction) => {
      const serviceId = transaction.serviceId;
      if (serviceMapping[serviceId]) {
        serviceMapping[serviceId].numTransactions++;
        serviceMapping[serviceId].totalAmount += parseFloat(
          transaction.totalAmount
        );
      }
    });

    const updatedServiceDataArray = Object.values(serviceMapping);
    setServiceData(updatedServiceDataArray);
  };

  const handleIntervalButtonClick = (interval) => {
    setSelectedInterval(interval);
    filterTransactionsByInterval(interval);
  };

  const calculateTotalStats = () => {
    const totalStats = {
      numTransactions: 0,
      totalAmount: 0,
    };

    serviceData.forEach((service) => {
      totalStats.numTransactions += service.numTransactions;
      totalStats.totalAmount += service.totalAmount;
    });

    return totalStats;
  };

  const getDateRange = () => {
    const currentDate = new Date();
    const endDate = currentDate.toLocaleDateString("en-US");
    let startDate;

    switch (selectedInterval) {
      case "daily":
        startDate = currentDate.toLocaleDateString("en-US");
        break;
      case "weekly":
        const oneWeekAgo = new Date(
          currentDate.getTime() - 7 * 24 * 60 * 60 * 1000
        );
        startDate = oneWeekAgo.toLocaleDateString("en-US");
        break;
      case "monthly":
        const oneMonthAgo = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 1,
          1
        );
        startDate = oneMonthAgo.toLocaleDateString("en-US");
        break;
      case "quarterly":
        const threeMonthsAgo = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 3,
          1
        );
        startDate = threeMonthsAgo.toLocaleDateString("en-US");
        break;
      case "yearly":
        const oneYearAgo = new Date(currentDate.getFullYear() - 1, 0, 1);
        startDate = oneYearAgo.toLocaleDateString("en-US");
        break;
      default:
        startDate = currentDate.toLocaleDateString("en-US");
    }

    return `${startDate} to ${endDate}`;
  };

  const filterTransactionsByDateRange = (start, end) => {
    // Set the start time to 00:00:00 and end time to 23:59:59 for inclusive filtering
    const startTime = new Date(start);
    startTime.setHours(0, 0, 0, 0); // Set to the start of the day (00:00:00)

    const endTime = new Date(end);
    endTime.setHours(23, 59, 59, 999); // Set to the end of the day (23:59:59.999)

    const filteredTransactions = transactions.filter((transaction) => {
      const transactionDate = new Date(transaction.created_at);
      return transactionDate >= startTime && transactionDate <= endTime;
    });

    const serviceMapping = {};

    serviceData.forEach((service) => {
      serviceMapping[service.serviceId] = {
        serviceId: service.serviceId,
        serviceName: service.serviceName,
        numTransactions: 0,
        totalAmount: 0,
      };
    });

    filteredTransactions.forEach((transaction) => {
      const serviceId = transaction.serviceId;
      if (serviceMapping[serviceId]) {
        serviceMapping[serviceId].numTransactions++;
        serviceMapping[serviceId].totalAmount += parseFloat(
          transaction.totalAmount
        );
      }
    });

    const updatedServiceDataArray = Object.values(serviceMapping);
    setServiceData(updatedServiceDataArray);
  };

  const handleStartDateChange = (event) => {
    const selectedStartDate = new Date(event.target.value);
    setStartDate(selectedStartDate);
    filterTransactionsByDateRange(selectedStartDate, endDate);
  };

  const handleEndDateChange = (event) => {
    const selectedEndDate = new Date(event.target.value);
    setEndDate(selectedEndDate);
    filterTransactionsByDateRange(startDate, selectedEndDate);
  };

  const clearStartDate = () => {
    setStartDate(new Date());
    filterTransactionsByDateRange(new Date(), endDate);
  };

  const clearEndDate = () => {
    setEndDate(new Date());
    filterTransactionsByDateRange(startDate, new Date());
  };

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet([
      ...serviceData.map((service) => ({
        "Service Name": service.serviceName,
        "Number of Transactions": service.numTransactions,
        "Total Amount": service.totalAmount.toFixed(2),
      })),
      {
        "Service Name": "Total",
        "Number of Transactions": calculateTotalStats().numTransactions,
        "Total Amount": calculateTotalStats().totalAmount.toFixed(2),
      },
    ]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Transactions");
    XLSX.writeFile(wb, "transactions.xlsx");
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.setFont("helvetica");
    doc.setFontSize(12);

    const dateRange = getDateRange();
    doc.text(`Transaction Details (${dateRange})`, 15, 15);

    const tableData = serviceData.map((service) => [
      service.serviceName,
      service.numTransactions,
      service.totalAmount.toFixed(2),
    ]);

    tableData.push([
      "Total",
      calculateTotalStats().numTransactions,
      calculateTotalStats().totalAmount.toFixed(2),
    ]);

    doc.autoTable({
      headStyles: {
        fillColor: [41, 128, 185],
        textColor: 255,
      },
      bodyStyles: {
        textColor: [0, 0, 0],
      },
      margin: { top: 25 },
      head: [["Service Name", "Number of Transactions", "Total Amount"]],
      body: tableData,
    });

    doc.save("transaction_details.pdf");
  };

  const openExportMenu = (event) => {
    setExportMenuAnchor(event.currentTarget);
  };

  const closeExportMenu = () => {
    setExportMenuAnchor(null);
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Transaction Details
      </Typography>

      <Grid container spacing={2}>
        <Grid item>
          <Button
            variant={selectedInterval === "daily" ? "contained" : "outlined"}
            onClick={() => handleIntervalButtonClick("daily")}
          >
            Daily
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant={selectedInterval === "weekly" ? "contained" : "outlined"}
            onClick={() => handleIntervalButtonClick("weekly")}
          >
            Weekly
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant={selectedInterval === "monthly" ? "contained" : "outlined"}
            onClick={() => handleIntervalButtonClick("monthly")}
          >
            Monthly
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant={
              selectedInterval === "quarterly" ? "contained" : "outlined"
            }
            onClick={() => handleIntervalButtonClick("quarterly")}
          >
            Quarterly
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant={selectedInterval === "yearly" ? "contained" : "outlined"}
            onClick={() => handleIntervalButtonClick("yearly")}
          >
            Yearly
          </Button>

     
        </Grid>

     

        <Grid container alignItems="center" justifyContent="flex-end">
          {/* Export Button with Dropdown */}
          <IconButton
            aria-label="Export"
            aria-controls="export-menu"
            aria-haspopup="true"
            onClick={openExportMenu}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="export-menu"
            anchorEl={exportMenuAnchor}
            open={Boolean(exportMenuAnchor)}
            onClose={closeExportMenu}
          >
            <MenuItem
              onClick={() => {
                exportToExcel();
                closeExportMenu();
              }}
            >
              <GetAppIcon /> Export to Excel
            </MenuItem>
            <MenuItem
              onClick={() => {
                exportToPDF();
                closeExportMenu();
              }}
            >
              <GetAppIcon /> Export to PDF
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>



      <Typography variant="subtitle1" gutterBottom>
  Date Range: {getDateRange()}
</Typography>

<Grid container spacing={2} alignItems="center" style={{ marginTop: '16px' }}>
  <Grid item>
    <TextField
      label="Start Date"
      type="date"
      value={startDate.toISOString().split("T")[0]}
      onChange={handleStartDateChange}
      InputLabelProps={{
        shrink: true,
      }}
    />
  </Grid>
  <Grid item>
    <Typography variant="body2">to</Typography>
  </Grid>
  <Grid item>
    <TextField
      label="End Date"
      type="date"
      value={endDate.toISOString().split("T")[0]}
      onChange={handleEndDateChange}
      InputLabelProps={{
        shrink: true,
      }}
    />
  </Grid>
  <Grid item>
    <Button
      variant="outlined"
      size="small"
      onClick={clearStartDate}
    >
      Clear Start Date
    </Button>
  </Grid>
  <Grid item>
    <Button
      variant="outlined"
      size="small"
      onClick={clearEndDate}
    >
      Clear End Date
    </Button>
  </Grid>
</Grid>


      {/* Display fetched statistics for the selected interval */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Service Name</TableCell>
              <TableCell>Number of Transactions</TableCell>
              <TableCell>Total Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {serviceData.map((service) => (
              <TableRow key={service.serviceId}>
                <TableCell>{service.serviceName}</TableCell>
                <TableCell>{service.numTransactions}</TableCell>
                <TableCell>{service.totalAmount.toFixed(2)}</TableCell>
              </TableRow>
            ))}

            {/* Total row */}
            <TableRow>
              <TableCell>Total</TableCell>
              <TableCell>{calculateTotalStats().numTransactions}</TableCell>
              <TableCell>
                {calculateTotalStats().totalAmount.toFixed(2)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default TransactionDetailsPage;

import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  MenuItem,
  Snackbar,
  Stack,
  Card,
  CardContent,
  Box,
} from "@mui/material";

import { useCompanyContext } from "../context/CompanyContext";
import { useServiceContext } from "../context/ServiceContext";
import instance from "../utils/axiosInstance";
import { base_url } from "../utils/baseUrl";
import BillModal from "./BillModal";

const CreateTransactionPage = () => {
  const { fetchCompanies } = useCompanyContext();
  const { fetchServices } = useServiceContext();

  const [serviceId, setServiceId] = useState("");

  const [totalAmount, setTotalAmount] = useState(0);

  const [status] = useState("open");

  const [successMessage, setSuccessMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [services, setServices] = useState([]);

  const [companies, setCompanies] = useState([]);
  const [selectedServicePrice, setSelectedServicePrice] = useState(0);

  const [transactionData, setTransactionData] = useState(null);
  const [transactionId, setTransactionId] = useState(null);
  const [showBillModal, setShowBillModal] = useState(false);

  useEffect(() => {
    if (successMessage) {
      setSnackbarOpen(true);
    }
  }, [successMessage]);

  useEffect(() => {
    fetchServicesData();
    fetchCompaniesData();
  }, []);

  const fetchCompaniesData = async () => {
    try {
      const fetchedCompanies = await fetchCompanies();
      setCompanies(fetchedCompanies);
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };

  const fetchServicesData = async () => {
    try {
      const fetchedServices = await fetchServices();
      setServices(fetchedServices);
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  const handleCreateTransaction = async () => {
    const newTransactionData = {
      serviceId,
      status,
      totalAmount,
      departureTime: "0000-00-00 00:00:00",
      timeDuration: 1,
    };

    console.log("New Transaction Data:", newTransactionData);

    try {
      const response = await instance.post(
        `${base_url}transaction`,
        newTransactionData
      );

      if (response.data.success) {
        const transactionId = response.data.transactionId;
        const selectedService = services.find(
          (service) => service.id === serviceId
        );
        const selectedServiceName = selectedService
          ? selectedService.serviceName
          : "Unknown Service";

        const selectedCompanyName =
          companies.length > 0 ? companies[0].companyName : "Unknown Company";
        const selectedAddress =
          companies.length > 0
            ? companies[0].companyAddress
            : "Unknown Company";

        const billData = {
          companyAddress: selectedAddress,
          companyName: selectedCompanyName,
          serviceName: selectedServiceName,
          selectedServicePrice,
          createdAt: new Date().toISOString(),
          transactionId: transactionId,
        };

        console.log("Transaction Created:", response.data); // Add this console log

        setTransactionData(billData);
        setTransactionId(transactionId);
        setSuccessMessage("Transaction created successfully");
        resetFields();
        setShowBillModal(true);
      } else {
        console.log("Failed to create transaction:", response.data); // Add this console log
        setSuccessMessage("Failed to create transaction");
      }
    } catch (error) {
      console.error("Error creating transaction:", error);
      setSuccessMessage("An error occurred");
    }
  };

  const resetFields = () => {
    setServiceId("");
    setTotalAmount(0);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSuccessMessage("");
  };

  return (
    <Container>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4" gutterBottom>
          Transaction
        </Typography>
      </Stack>
      <Card elevation={3}>
        <CardContent>
          <Box py={2}>
            <Typography variant="h5" gutterBottom>
              Creat new Bill
            </Typography>
            <Typography variant="body1" color="textSecondary">
              Please fill in the details below to generate new bill.
            </Typography>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <TextField
                label="Service"
                variant="outlined"
                fullWidth
                select
                value={serviceId}
                onChange={(e) => {
                  const selectedServiceId = e.target.value;
                  setServiceId(selectedServiceId);

                  const selectedService = services.find(
                    (service) => service.id === selectedServiceId
                  );

                  if (selectedService) {
                    setSelectedServicePrice(selectedService.price);

                    // Update the totalAmount based on the selected service's price
                    setTotalAmount(selectedService.price);
                  } else {
                    setSelectedServicePrice(0);

                    // Reset the totalAmount if the selected service is not found
                    setTotalAmount(0);
                  }
                }}
              >
                {services.map((service) => (
                  <MenuItem key={service.id} value={service.id}>
                    {service.serviceName}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleCreateTransaction}
              >
                Create
              </Button>
            </Grid>
          </Grid>

          <BillModal
            isOpen={showBillModal}
            onClose={() => setShowBillModal(false)}
            billData={transactionData}
          />

          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
            message={successMessage}
          />
        </CardContent>
      </Card>
    </Container>
  );
};

export default CreateTransactionPage;

import React, { createContext, useContext } from "react";
import instance from "../utils/axiosInstance";

import { base_url } from "../utils/baseUrl";

const ServiceContext = createContext();

export const useServiceContext = () => useContext(ServiceContext);

export const ServiceProvider = ({ children }) => {
  const createService = async (serviceData) => {
    try {
      const response = await instance.post(
        `${base_url}service`,
        serviceData,
        {}
      );
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  };

  const fetchServices = async () => {
    try {
      const response = await instance.get(`${base_url}service/services`);
      return response.data.services;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  };

  const deleteService = async (serviceId) => {
    try {
      const response = await instance.delete(
        `${base_url}service/services/${serviceId}`,
        {}
      );
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  };

  const editService = async (serviceId, updatedServiceData) => {
    try {
      const response = await instance.put(
        `${base_url}service/edit/${serviceId}`,
        updatedServiceData,
        {}
      );
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  };

  return (
    <ServiceContext.Provider
      value={{ createService, fetchServices, deleteService, editService }}
    >
      {children}
    </ServiceContext.Provider>
  );
};

import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Grid,
  IconButton,
} from "@mui/material";
import { useServiceContext } from "../context/ServiceContext";
import Iconify from "../components/iconify";
import InputAdornment from "@mui/material/InputAdornment";

const ServiceTableView = () => {
  const { fetchServices, createService, deleteService, editService } = useServiceContext();
  const [services, setServices] = useState([]);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [serviceName, setServiceName] = useState("");
  const [description, setDescription] = useState("");
  const [timeDuration, setTimeDuration] = useState("");
  const [price, setPrice] = useState("");
  const [status, setStatus] = useState("active");
  const [activeServicesOnTop, setActiveServicesOnTop] = useState(true);
  const [editingServiceId, setEditingServiceId] = useState(null); // Add this state for edit

  const fetchServicesData = async () => {
    try {
      const fetchedServices = await fetchServices();
      setServices(fetchedServices);
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  useEffect(() => {
    fetchServicesData();
  }, []);

  const openDialog = () => {
    setDialogOpen(true);
    setStatus("active");
    setEditingServiceId(null); // Reset editing ID when opening dialog for add
  };

  const closeDialog = () => {
    setDialogOpen(false);
    setServiceName("");
    setDescription("");
    setTimeDuration("");
    setPrice("");
    setStatus("active");
    setEditingServiceId(null); // Reset editing ID when closing dialog
  };

  const handleEditServiceClick = (service) => {
    setDialogOpen(true);
    setServiceName(service.serviceName);
    setDescription(service.description);
    setTimeDuration(service.timeDuration);
    setPrice(service.price);
    setStatus(service.status);
    setEditingServiceId(service.id); // Set the editing ID
  };

  const handleAddService = async () => {
    const serviceData = {
      serviceName,
      description,
      timeDuration,
      price,
      status,
    };

    try {
      if (editingServiceId) {
        await editService(editingServiceId, serviceData); // Edit existing service
      } else {
        await createService(serviceData); // Add new service
      }
      fetchServicesData();
      closeDialog();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDeleteService = async (serviceId) => {
    try {
      await deleteService(serviceId);
      fetchServicesData();
    } catch (error) {
      console.error("Error deleting service:", error);
    }
  };

  const toggleFilter = () => {
    setActiveServicesOnTop((prev) => !prev);
  };


  return (
    <Container>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4" gutterBottom>
          Service
        </Typography>
        <Button
          variant="contained"
          startIcon={<Iconify icon="eva:plus-fill" />}
          onClick={openDialog}
        >
          Add
        </Button>
      </Stack>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Service Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Time Duration</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>
                Status{" "}
                <IconButton
                  onClick={toggleFilter}
                  style={{ cursor: "pointer" }}
                >
                  {activeServicesOnTop ? (
                    <Iconify icon="ic:baseline-arrow-upward" />
                  ) : (
                    <Iconify icon="ic:baseline-arrow-downward" />
                  )}
                </IconButton>
              </TableCell>

              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {services
              .slice()
              .sort((a, b) => {
                if (activeServicesOnTop) {
                  return a.status === "active" ? -1 : 1;
                } else {
                  return a.status === "inactive" ? -1 : 1;
                }
              })
                    .map((service) => (
                <TableRow key={service.id}>
                  <TableCell>{service.id}</TableCell>
                  <TableCell>{service.serviceName}</TableCell>
                  <TableCell>{service.description}</TableCell>
                  <TableCell>{service.timeDuration}</TableCell>
                  <TableCell>{service.price}</TableCell>
                  <TableCell>{service.status}</TableCell>
                  <TableCell>
                    <Stack direction="row" spacing={1}>
                      <Iconify icon="ic:outline-delete" onClick={() => handleDeleteService(service.id)} style={{ cursor: "pointer" }} />
                      <Iconify icon="ic:outline-edit" onClick={() => handleEditServiceClick(service)} style={{ cursor: "pointer" }} />
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={isDialogOpen} onClose={closeDialog}>
      <DialogTitle>{editingServiceId ? "Edit Service" : "Add New Service"}</DialogTitle>
        <Grid>
          <DialogContent sx={{ display: "grid", gap: 2 }}>
            <TextField
              label="Service Name"
              variant="outlined"
              fullWidth
              value={serviceName}
              onChange={(e) => setServiceName(e.target.value)}
            />
            <TextField
              label="Description"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              margin="normal"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Time Duration"
                  variant="outlined"
                  fullWidth
                  value={timeDuration}
                  onChange={(e) => setTimeDuration(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Price"
                  variant="outlined"
                  fullWidth
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Rs.</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <TextField
              label="Status"
              variant="outlined"
              select
              fullWidth
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="inactive">Inactive</MenuItem>
            </TextField>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleAddService} color="primary">
              {editingServiceId ? "Save" : "Add"}
            </Button>
          </DialogActions>
        </Grid>
      </Dialog>
    </Container>
  );
};

export default ServiceTableView;

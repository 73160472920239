import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Grid, Container, Typography } from "@mui/material";
import {
  AppCurrentVisits,
  AppWidgetSummary,
  AppWebsiteVisits,
} from "../sections/@dashboard/app";
import { useTransactionContext } from "../context/TransactionContext ";
import { useTheme } from "@mui/material/styles";
import { useServiceContext } from "../context/ServiceContext";

export default function DashboardAppPage() {
  const theme = useTheme();
  const { fetchTransactions } = useTransactionContext();
  const { fetchServices } = useServiceContext(); // Use the fetchServices function from ServiceContext
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [todayTotalTransactions, setTodayTotalTransactions] = useState(0);
  const [todayTotalAmount, setTodayTotalAmount] = useState(0);
  const [uniqueServiceIds, setUniqueServiceIds] = useState([]);
  const [serviceTotals, setServiceTotals] = useState({}); // Store service totals
  const [serviceNames, setServiceNames] = useState({}); // Store service names
  const [chartStartLabel, setChartStartLabel] = useState("");
  const [chartEndLabel, setChartEndLabel] = useState("");
  const [serviceDailyTransactions, setServiceDailyTransactions] = useState({});

  useEffect(() => {
    // Call fetchTransactions to fetch the transactions
    fetchTransactions()
      .then(async (transactions) => {
        // Fetch services to get service names
        const services = await fetchServices();

        // Calculate the total number of transactions
        const total = transactions.length;
        setTotalTransactions(total);

        // Calculate the total amount from all transactions
        const totalAmt = transactions.reduce((acc, transaction) => {
          return acc + parseFloat(transaction.totalAmount);
        }, 0);
        setTotalAmount(totalAmt);

        // Extract unique service IDs and calculate totals
        const uniqueIds = Array.from(
          new Set(transactions.map((transaction) => transaction.serviceId))
        );
        setUniqueServiceIds(uniqueIds);

        const dailyTransactions = {};
        uniqueIds.forEach((id) => {
          const serviceTransactions = transactions.filter(
            (transaction) => transaction.serviceId === id
          );

          // Group transactions by date
          const groupedTransactions = {};
          serviceTransactions.forEach((transaction) => {
            const transactionDate = new Date(transaction.created_at)
              .toISOString()
              .split("T")[0];

            if (!groupedTransactions[transactionDate]) {
              groupedTransactions[transactionDate] = 0;
            }
            groupedTransactions[transactionDate]++;
          });

          dailyTransactions[id] = groupedTransactions;
        });

        setServiceDailyTransactions(dailyTransactions);

        const serviceTotalMap = {};
        const serviceNameMap = {}; // Store service names
        uniqueIds.forEach((id) => {
          const serviceTransactions = transactions.filter(
            (transaction) => transaction.serviceId === id
          );
          const serviceTotal = serviceTransactions.reduce(
            (acc, transaction) => {
              return acc + parseFloat(transaction.totalAmount);
            },
            0
          );
          serviceTotalMap[id] = serviceTotal;

          // Find and store service name
          const service = services.find((service) => service.id === id);
          if (service) {
            serviceNameMap[id] = service.serviceName;
          }
        });

        setServiceTotals(serviceTotalMap);
        setServiceNames(serviceNameMap); // Set service names

        // Debug: Print the current date
        const currentDate = new Date();
        const today = currentDate.toISOString().split("T")[0];
        console.log("Current Date:", today);

        if (transactions.length > 0) {
          const firstTransactionDate = new Date(transactions[0].created_at);
          setChartStartLabel(firstTransactionDate.toISOString().split("T")[0]);
        } else {
          // Handle the case where there are no transactions
          setChartStartLabel("No Transactions");
        }

        const mid12 = new Date(currentDate);
        mid12.setHours(12, 0, 0, 0); // Set to mid-12 (12:00:00.000)
        setChartEndLabel(mid12.toISOString().split("T")[0]);

        // Calculate the start and end timestamps for today
        const startOfDay = new Date(currentDate);
        startOfDay.setHours(0, 0, 0, 0); // Set to midnight (00:00:00.000)
        const endOfDay = new Date(currentDate);
        endOfDay.setHours(23, 59, 59, 999); // Set to 11:59:59.999

        // Filter transactions for today
        const todayTransactions = transactions.filter((transaction) => {
          const transactionDate = transaction.created_at
            ? new Date(transaction.created_at)
            : null;

          // Check if the transactionDate falls within the current day
          return transactionDate >= startOfDay && transactionDate <= endOfDay;
        });

        // Calculate total transactions and earnings for today
        const todayTotal = todayTransactions.length;
        const todayTotalAmt = todayTransactions.reduce((acc, transaction) => {
          return acc + parseFloat(transaction.totalAmount);
        }, 0);
        setTodayTotalTransactions(todayTotal);
        setTodayTotalAmount(todayTotalAmt);

        console.log("Total Transactions:", total);
        console.log("Total Amount:", totalAmt);
        console.log("Today's Total Transactions:", todayTotal);
        console.log("Today's Total Amount:", todayTotalAmt);
      })
      .catch((error) => {
        // Handle any errors that occur during the fetch
        console.error("Error fetching transactions:", error);
      });
  }, []);

  console.log("Rendering DashboardAppPage");

  // Format data for the chart
  const chartData = uniqueServiceIds.map((serviceId) => ({
    label: `${serviceNames[serviceId]}`, // Use the service name
    value: serviceTotals[serviceId],
  }));

  return (
    <>
      <Helmet>
        <title> Dashboard | Minimal UI </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total Transactions"
              total={totalTransactions}
              icon={"icon-park:sales-report"}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total Earnings"
              total={totalAmount}
              color="info"
              icon={"icon-park:ticket"}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Today's Total Transactions"
              total={todayTotalTransactions}
              icon={"icon-park:sales-report"}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Today's Total Earnings"
              total={todayTotalAmount}
              color="info"
              icon={"icon-park:ticket"}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title="Transaction Chart"
              chartLabels={[chartStartLabel, chartEndLabel]}
              chartData={uniqueServiceIds.map((serviceId) => ({
                name: serviceNames[serviceId],
                fill: "solid",
                data: Object.values(serviceDailyTransactions[serviceId] || {}),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Total service with sales"
              chartData={chartData}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.error.main,
              ]}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

import React, { createContext, useContext } from "react";
import instance from "../utils/axiosInstance";
import { useAuthContext } from "./AuthContext";
import { base_url } from "../utils/baseUrl";

const MembershipContext = createContext();

export const useMembershipContext = () => useContext(MembershipContext);

export const MembershipProvider = ({ children }) => {
  const { user } = useAuthContext();

  const createMember = async (memberData) => {
    try {
      const response = await instance.post(`${base_url}member/create`, memberData, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  };

  const fetchMembers = async () => {
    try {
      const response = await instance.get(`${base_url}member/fetch`);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  };

  const deleteMember = async (memberId) => {
    try {
      const response = await instance.delete(`${base_url}member/delete/${memberId}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  };


  return (
    <MembershipContext.Provider value={{ createMember, fetchMembers, deleteMember  }}>
      {children}
    </MembershipContext.Provider>
  );
};

import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Grid,
} from "@mui/material";
import { useCompanyContext } from "../context/CompanyContext";
import Iconify from "../components/iconify";

const CompanyTableView = () => {
  const { fetchCompanies, createCompany, deleteCompany, editCompany } =
    useCompanyContext();
  const [companies, setCompanies] = useState([]);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyPanVatNo, setCompanyPanVatNo] = useState("");
  const [companyTelPhone, setCompanyTelPhone] = useState("");
  const [status, setStatus] = useState("Active");
  const [editingCompanyId, setEditingCompanyId] = useState(null);

  const fetchCompaniesData = async () => {
    try {
      const fetchedCompanies = await fetchCompanies();
      setCompanies(fetchedCompanies);
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };

  useEffect(() => {
    fetchCompaniesData();
  }, []);

  const openDialog = () => {
    setDialogOpen(true);
    setStatus("Active");
    setEditingCompanyId(null);
  };

  const closeDialog = () => {
    setDialogOpen(false);
    setCompanyName("");
    setCompanyAddress("");
    setCompanyPanVatNo("");
    setCompanyTelPhone("");
    setStatus("Active");
    setEditingCompanyId(null);
  };

  const handleEditCompanyClick = (company) => {
    setDialogOpen(true);
    setCompanyName(company.companyName);
    setCompanyAddress(company.companyAddress);
    setCompanyPanVatNo(company.companyPanVatNo);
    setCompanyTelPhone(company.companyTelPhone);
    setStatus(company.status);
    setEditingCompanyId(company.id);
  };

  const handleAddCompany = async () => {
    const companyData = {
      companyName,
      companyAddress,
      companyPanVatNo,
      companyTelPhone,
      status,
    };

    try {
      if (editingCompanyId) {
        await editCompany(editingCompanyId, companyData);
      } else {
        await createCompany(companyData);
      }
      fetchCompaniesData();
      closeDialog();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDeleteCompany = async (companyId) => {
    try {
      await deleteCompany(companyId);
      fetchCompaniesData();
    } catch (error) {
      console.error("Error deleting company:", error);
    }
  };

  return (
    <Container>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4" gutterBottom>
          Company
        </Typography>
        <Button
          variant="contained"
          startIcon={<Iconify icon="eva:plus-fill" />}
          onClick={openDialog}
        >
          Add
        </Button>
      </Stack>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Company Name</TableCell>
              <TableCell>Company Address</TableCell>
              <TableCell>PAN/VAT No.</TableCell>
              <TableCell>Telephone</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companies.map((company) => (
              <TableRow key={company.id}>
                <TableCell>{company.id}</TableCell>
                <TableCell>{company.companyName}</TableCell>
                <TableCell>{company.companyAddress}</TableCell>
                <TableCell>{company.companyPanVatNo}</TableCell>
                <TableCell>{company.companyTelPhone}</TableCell>
                <TableCell>{company.status}</TableCell>
                <TableCell>
                  <Stack direction="row" spacing={1}>
                    <Iconify
                      icon="ic:outline-delete"
                      onClick={() => handleDeleteCompany(company.id)}
                      style={{ cursor: "pointer" }}
                    />
                    <Iconify
                      icon="ic:outline-edit"
                      onClick={() => handleEditCompanyClick(company)}
                      style={{ cursor: "pointer" }}
                    />
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={isDialogOpen} onClose={closeDialog}>
        <DialogTitle>
          {editingCompanyId ? "Edit Company" : "Add New Company"}
        </DialogTitle>
        <Grid>
          <DialogContent sx={{ display: "grid", gap: 2 }}>
            <TextField
              label="Company Name"
              variant="outlined"
              fullWidth
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />

            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Company Address"
                  variant="outlined"
                  fullWidth
                  value={companyAddress}
                  onChange={(e) => setCompanyAddress(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="PAN/VAT No."
                  variant="outlined"
                  fullWidth
                  value={companyPanVatNo}
                  onChange={(e) => setCompanyPanVatNo(e.target.value)}
                />
              </Grid>
            </Grid>

            <TextField
              label="Telephone"
              variant="outlined"
              fullWidth
              value={companyTelPhone}
              onChange={(e) => setCompanyTelPhone(e.target.value)}
            />
            <TextField
              label="Status"
              variant="outlined"
              select
              fullWidth
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="Inactive">Inactive</MenuItem>
            </TextField>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleAddCompany} color="primary">
              {editingCompanyId ? "Save" : "Add"}
            </Button>
          </DialogActions>
        </Grid>
      </Dialog>
    </Container>
  );
};

export default CompanyTableView;

import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  MenuItem,
  Button,
  Grid,
  Menu,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useServiceContext } from "../context/ServiceContext";

import Iconify from "../components/iconify";
import { useTransactionContext } from "../context/TransactionContext ";
import * as XLSX from "xlsx";

import jsPDF from "jspdf";
import "jspdf-autotable";

const TransactionTableView = () => {
  const { fetchTransactions, deleteTransaction, editTransaction } =
    useTransactionContext();
  const [transactions, setTransactions] = useState([]);
  const { fetchServices } = useServiceContext();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [serviceId, setServiceId] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [departureTime, setDepartureTime] = useState("");
  const [editStatus, setEditStatus] = useState(""); // Separate state for edit status
  const [timeDuration, setTimeDuration] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [editingTransactionId, setEditingTransactionId] = useState(null);
  const [exportMenuAnchor, setExportMenuAnchor] = useState(null);
  const [serviceData, setServiceData] = useState([]);

  const fetchTransactionsData = async () => {
    try {
      const fetchedTransactions = await fetchTransactions({
        status: filterStatus,
        startDate: filterStartDate,
        endDate: filterEndDate,
      });

      setTransactions(fetchedTransactions);
    } catch (error) {
      console.error("Error fetching transactions:", error);
    }
  };

  useEffect(() => {
    fetchTransactionsData();
    fetchServiceData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStatus, filterStartDate, filterEndDate]);

  const fetchServiceData = async () => {
    try {
      const fetchedServices = await fetchServices();
      setServiceData(fetchedServices);
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  const closeDialog = () => {
    setDialogOpen(false);
    setTransactionId("");
    setServiceId("");
    setTotalAmount("");
    setDepartureTime("");
    setEditStatus("");
    setTimeDuration("");
    setEditingTransactionId(null);
  };

  const handleEditTransactionClick = (transaction) => {
    setDialogOpen(true);
    setTransactionId(transaction.id);

    setEditStatus(transaction.status); // Set edit status

    setEditingTransactionId(transaction.id);
  };

  const handleDeleteTransaction = async (transactionId) => {
    try {
      await deleteTransaction(transactionId);
      fetchTransactionsData();
    } catch (error) {
      console.error("Error deleting transaction:", error);
    }
  };

  const handleStatusChange = (event) => {
    setFilterStatus(event.target.value);
  };

  const handleStartDateFilterChange = (event) => {
    setFilterStartDate(event.target.value);
  };

  const handleEndDateFilterChange = (event) => {
    setFilterEndDate(event.target.value);
  };

  const handleEditSave = async () => {
    const transactionData = {
      status: editStatus,

      startDate: filterStartDate,
      endDate: filterEndDate,
    };

    try {
      if (editingTransactionId) {
        await editTransaction(editingTransactionId, transactionData);
      } else {
        // Handle adding new transaction
      }
      fetchTransactionsData();
      closeDialog();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const exportToExcel = () => {
    // Create a copy of transactions with unwanted fields removed
    const transactionsToExport = transactions.map((transaction) => ({
      id: transaction.id,
      serviceName: serviceData.find((service) => service.id === transaction.serviceId)?.serviceName || "",
      totalAmount: transaction.totalAmount,
      departureTime: new Date(transaction.departureTime).toLocaleString(),
      status: transaction.status,
      timeDuration: `${transaction.timeDuration} minutes`,
    }));
  
    const ws = XLSX.utils.json_to_sheet(transactionsToExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Transactions");
    XLSX.writeFile(wb, "transactions.xlsx");
  };
  

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.text("Transactions", 10, 10);
  
    const tableData = transactions.map((transaction) => [
      transaction.id,
      serviceData.find((service) => service.id === transaction.serviceId)?.serviceName || "",
      transaction.totalAmount,
      new Date(transaction.departureTime).toLocaleString(),
      transaction.status,
      `${transaction.timeDuration} minutes`,
    ]);

    // Use autoTable to generate the table
    doc.autoTable({
      head: [
        [
          "ID",
          "Service ID",
          "Total Amount",
          "Departure Time",
          "Status",
          "Time Duration",
        ],
      ],
      body: tableData,
    });

    doc.save("transactions.pdf");
  };

  const openExportMenu = (event) => {
    setExportMenuAnchor(event.currentTarget);
  };

  // Function to close the export menu
  const closeExportMenu = () => {
    setExportMenuAnchor(null);
  };

  return (
    <Container>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4" gutterBottom>
          Transactions
        </Typography>
      </Stack>

      {/* Filter Section */}
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        spacing={2}
      >
        <TextField
          select
          variant="outlined"
          value={filterStatus}
          onChange={handleStatusChange}
          label="Status"
          sx={{ width: "200px" }} // Adjust the width as needed
        >
          <MenuItem value="">All</MenuItem>
          <MenuItem value="open">Open</MenuItem>
          <MenuItem value="closed">Closed</MenuItem>
        </TextField>

        <TextField
          type="date"
          variant="outlined"
          value={filterStartDate}
          onChange={handleStartDateFilterChange}
          label="Start Date"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Typography variant="h6">to</Typography>
        <TextField
          type="date"
          variant="outlined"
          value={filterEndDate}
          onChange={handleEndDateFilterChange}
          label="End Date"
          InputLabelProps={{
            shrink: true,
          }}
        />
        {/* Export Button with Dropdown */}
        <IconButton
          aria-label="Export"
          aria-controls="export-menu"
          aria-haspopup="true"
          onClick={openExportMenu}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="export-menu"
          anchorEl={exportMenuAnchor}
          open={Boolean(exportMenuAnchor)}
          onClose={closeExportMenu}
        >
          <MenuItem
            onClick={() => {
              exportToExcel();
              closeExportMenu();
            }}
          >
            Export to Excel
          </MenuItem>
          <MenuItem
            onClick={() => {
              exportToPDF();
              closeExportMenu();
            }}
          >
            Export to PDF
          </MenuItem>
        </Menu>
      </Stack>

      {/* Transaction Table */}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Service Name</TableCell> {/* Display serviceName instead of serviceId */}
              <TableCell>Total Amount</TableCell>
              <TableCell>Departure Time</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Time Duration</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.map((transaction) => (
              <TableRow key={transaction.id}>
                <TableCell>{transaction.id}</TableCell>
                <TableCell>
                  {serviceData.find((service) => service.id === transaction.serviceId)?.serviceName || ""}
                </TableCell>
                <TableCell>{transaction.totalAmount}</TableCell>
                <TableCell>
                  {new Date(transaction.departureTime).toLocaleString()}
                </TableCell>
                <TableCell>{transaction.status}</TableCell>
                <TableCell>{transaction.timeDuration} minutes</TableCell>
                <TableCell>
                  <Stack direction="row" spacing={1}>
                    <IconButton
                      onClick={() => handleDeleteTransaction(transaction.id)}
                    >
                      <Iconify icon="ic:outline-delete" />
                    </IconButton>
                    <IconButton
                      onClick={() => handleEditTransactionClick(transaction)}
                    >
                      <Iconify icon="ic:outline-edit" />
                    </IconButton>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Edit Transaction Dialog */}
      <Dialog open={isDialogOpen} onClose={closeDialog}>
        <DialogTitle>
          {editingTransactionId ? "Edit Transaction" : "Add New Transaction"}
        </DialogTitle>
        <Grid>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  select
                  label="Status"
                  variant="outlined"
                  fullWidth
                  value={editStatus}
                  onChange={(e) => setEditStatus(e.target.value)}
                >
                  <MenuItem value="open">Open</MenuItem>
                  <MenuItem value="closed">Closed</MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </DialogContent>
        </Grid>
        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleEditSave} color="primary">
            {editingTransactionId ? "Save" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default TransactionTableView;

import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Grid,
  IconButton
} from "@mui/material";
import { useMembershipTypeContext } from "../context/MembershipTypeContext"; // Import the MembershipTypeContext hooks
import Iconify from "../components/iconify";
import InputAdornment from "@mui/material/InputAdornment";

const MembershipTypeTableView = () => {
  const { fetchMembershipTypes, createMembershipType, deleteMembershipType  } =
    useMembershipTypeContext(); // Use the MembershipTypeContext hooks
  const [membershipTypes, setMembershipTypes] = useState([]);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [typeName, setTypeName] = useState("");
  const [typeDescription, setTypeDescription] = useState("");
  const [discountPercentage, setDiscountPercentage] = useState("");
  const [typeStatus, setTypeStatus] = useState("active");
  const [activeMemberTypeOnTop, setActiveMemberTypeOnTop] = useState(true);

  const fetchMembershipTypesData = async () => {
    try {
      const fetchedMembershipTypes = await fetchMembershipTypes();
      setMembershipTypes(fetchedMembershipTypes);
    } catch (error) {
      console.error("Error fetching membership types:", error);
    }
  };

  useEffect(() => {
    fetchMembershipTypesData();
  }, []);

  const openDialog = () => {
    setDialogOpen(true);
    setTypeStatus("inactive");
  };

  const closeDialog = () => {
    setDialogOpen(false);
    // Clear input fields when dialog is closed
    setTypeName("");
    setTypeDescription("");
    setDiscountPercentage("");
    setTypeStatus("inactive");
  };

  const handleAddMembershipType = async () => {
    const newMembershipType = {
      membershipType: typeName,
      description: typeDescription,
      discountPercentage: parseFloat(discountPercentage),
      status: typeStatus,
    };

    try {
      await createMembershipType(newMembershipType);
      fetchMembershipTypesData();
      closeDialog();
    } catch (error) {
      console.error("Error adding membership type:", error);
    }
  };

  const handleDeleteMembershipType = async (membershipTypeId) => {
    try {
      await deleteMembershipType(membershipTypeId);
      fetchMembershipTypesData();
    } catch (error) {
      console.error("Error deleting membership type:", error);
    }
  };

  const toggleFilter = () => {
    setActiveMemberTypeOnTop((prev) => !prev);
  };

  return (
    <Container>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4" gutterBottom>
          Membership Types
        </Typography>
        <Button
          variant="contained"
          startIcon={<Iconify icon="eva:plus-fill" />}
          onClick={openDialog}
        >
          Add
        </Button>
      </Stack>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Type Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Discount Percentage</TableCell>
              <TableCell>Status {" "}
              <IconButton
                  onClick={toggleFilter}
                  style={{ cursor: "pointer" }}
                >
                  {activeMemberTypeOnTop ? (
                    <Iconify icon="ic:baseline-arrow-upward" />
                  ) : (
                    <Iconify icon="ic:baseline-arrow-downward" />
                  )}
                </IconButton>
              </TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {membershipTypes
              .slice()
              .sort((a, b) => {
                if (activeMemberTypeOnTop) {
                  return a.status === "active" ? -1 : 1;
                } else {
                  return a.status === "inactive" ? -1 : 1;
                }
              })
              .map((type) => (
                <TableRow key={type.id}>
                  <TableCell>{type.id}</TableCell>
                  <TableCell>{type.membershipType}</TableCell>
                  <TableCell>{type.description}</TableCell>
                  <TableCell>{type.discountPercentage}%</TableCell>
                  <TableCell>{type.status}</TableCell>
                  <TableCell>
                    <Iconify
                      icon="ic:outline-delete"
                      onClick={() => handleDeleteMembershipType(type.id)}
                      style={{ cursor: "pointer" }}
                    />
                  </TableCell>
                </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={isDialogOpen} onClose={closeDialog}>
        <DialogTitle>Add New Membership Type</DialogTitle>
        <Grid>
          <DialogContent sx={{ display: "grid", gap: 2 }}>
            <TextField
              label="Type Name"
              variant="outlined"
              fullWidth
              value={typeName}
              onChange={(e) => setTypeName(e.target.value)}
            />
            <TextField
              label="Description"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              margin="normal"
              value={typeDescription}
              onChange={(e) => setTypeDescription(e.target.value)}
            />
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Discount"
                  variant="outlined"
                  fullWidth
                  value={discountPercentage}
                  onChange={(e) => setDiscountPercentage(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Status"
                  variant="outlined"
                  select
                  fullWidth
                  value={typeStatus}
                  onChange={(e) => setTypeStatus(e.target.value)}
                >
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="inactive">Inactive</MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleAddMembershipType} color="primary">
              Add
            </Button>
          </DialogActions>
        </Grid>
      </Dialog>
    </Container>
  );
};

export default MembershipTypeTableView;